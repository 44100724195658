export default function Navbar(){
    return (
        <div className=' fixed top-0 w-full bg-an_pink z-30 drop-shadow-md'>
            <nav className="flex justify-center gap-4 items-center px-8 py-2">
                <h3>Kai ❤️ Ling</h3>
            </nav>

        </div>
     
    );
}